

<template>
  <div>
    <v-text-field v-model="url" label="Youtube-URL"></v-text-field>
    <v-spacer></v-spacer>
    <v-btn @click="save" class="primary">Speichern</v-btn>

    <v-dialog
        v-model="dialog"
        width="480px"
    >
        <v-card>
            <v-card-title class="primary">
                Gespeichert
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>    
            </v-card-title>
            <v-card-text>Der Link wurde gespeichert!</v-card-text>
        </v-card>
    </v-dialog>
  </div>
  
</template>

<script>

export default {
  
  data () {
    return {
        dialog: false,
        url: ''
    };
  },
  methods: {
      save() {
          if(this.url != '') {
              this.$apiService.saveYoutubeUrl(this.url).then((res) => {
                console.log(res, 'save');
                this.dialog = true;
                this.url = '';
            });
          }

      }

  },
  mounted() {
      
  }
};
</script>
