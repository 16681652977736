<template>
  <div>

    <h4>Gottesdienste</h4>
    <v-data-table
      :headers="headers"
      :items="worship"
      fixed-header
      hide-default-footer

    >
        <!-- <template v-slot:item.transmissions="{ item }">
            <v-btn style="margin: 5px"
                v-for="(trans, index) in item.transmissions" :key="index"
            >
            <a :href="trans.url">{{ trans.name }}</a>
                
            </v-btn>
        </template> -->
    
    </v-data-table>
  </div>
</template>

<script>

export default {
  
  data () {
    return {
        headers: [
            {
                text: 'Tag',
                value: 'day',
                class:'secondary'
            },
            {
                text: 'Name',
                value: 'type',
                class:'secondary'
            },
            {
                text: 'Anmerkung',
                value: 'description',
                class:'secondary'
            },
            
            {
                text: 'Beginn',
                value: 'start_time',
                class:'secondary'
            },
            {
                text: 'Ende',
                value: 'end_time',
                class:'secondary'
            },
            /* {
                text: 'Übertragung',
                value: 'transmissions',
                class:'secondary'
            }, */

        ],
        worship: []
    };
  },
  methods: {
      getWorship() {
          this.$apiService.getWorship().then((res) => {
              this.worship = res.data;
          });
      }
  },
  mounted() {
      this.getWorship();
  }
};
</script>
