<template>
  <div >
    
    <v-form class="container" ref="registerForm">
        <v-card>
            <v-card-title class="secondary"><h3>{{registername}} - Anmeldung</h3></v-card-title>
            <v-card-subtitle class="secondary">{{formatRegisterDate}}</v-card-subtitle>
            <v-card-text class="row" style="margin: 10px 0px 0px 0px" >
                <v-flex xl12 lg12 md12 sm12 xs12 v-if="typeof this.$route.params.datetime == 'undefined'">
                    <v-autocomplete
                        v-model="register.date"
                        :items="allDates"
                        label="Datum Uhrzeit *"
                        :rules="[rules.required]"
                    ></v-autocomplete>
                </v-flex>

                <v-flex xl3 lg3 md6 sm6 xs6>
                    <v-text-field
                        v-model="register.name"
                        label="Vorname *"
                        :rules="[rules.required]"
                    ></v-text-field>
                </v-flex>
                <v-flex xl3 lg3 md6 sm6 xs6>
                    <v-text-field
                        v-model="register.surname"
                        label="Nachname *"
                        :rules="[rules.required]"
                    ></v-text-field>
                </v-flex>
                <v-flex xl5 lg4 md4 sm4 xs6>
                    <v-text-field
                        v-model="register.email"
                        label="Email"
                    ></v-text-field>
                </v-flex>
                <v-flex xl1 lg1 md1 sm1 xs6>
                    <v-text-field
                        v-model="register.count"
                        label="Anzahl der Personen"
                        type="number"
                        :rules="[rules.required]"
                    ></v-text-field>
                </v-flex>

               
                <div class="container row" style="margin-top: 10px">
                    <v-flex xl11 lg11 md11 sm11 xs11>
                        <v-row>
                        <v-checkbox
                            v-model="register.accept_data_protection"
                            label=""
                            :rules="[rules.required]"
                        >
                        
                        </v-checkbox>
                        <span style="padding: 22px 0px">
                            Ich akzeptiere, die <a @click="openDataProtectionDialog">Datenschutzbedingungen</a>
                        </span>
                        
                    </v-row>
                    </v-flex>
                </div>
                
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="save">Speichern</v-btn>
            </v-card-actions>
        </v-card>
        
    </v-form>
    <v-dialog
        v-model="dataProtectionDialog"
        max-width="1240px"
        v-if="dataProtectionDialog"
    >   
        <v-card>
            <v-card-title class="secondary">
                <v-spacer></v-spacer>
                <v-btn icon @click="dataProtectionDialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <data-protection></data-protection>
            </v-card-text>
        </v-card>
        
    </v-dialog>
    <v-dialog
        v-model="dialog"
        width="720"
        v-if="dialog"
    >
        <v-card>
            <v-card-title>
                Anmeldung durchgeführt
                <v-spacer>
                </v-spacer>
                <v-btn icon class="secondary" @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <p>{{dialogData.name}}, wir freuen uns auf deinen Besuch!


                </p>
                <p>Wir wünschen dir weiterhin Gottes Segen!</p>
            </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import dataProtection from './data-protection.vue';

export default {
  components: { dataProtection },
  
  data () {
    return {
        registername: 'Gottesdienst',
        formatRegisterDate: '',
        rules: {
            required: (value) => !!value || 'Daten sind erforderlich',
        },
        register: {
            date: '',
            time: '',
            count: 1
        },
        // status: [
        //     'Covid-19 Immunität (Geimpft / Genesen)',
        //     'Minderjährig',
        //     'Ich bringe einen gültigen Corona-Antigen Testnachweis mit.',
        //     'Ich benötige einen Corona-Antigentest.'
        // ],
        dialog: false,
        dialogData: {},
        dataProtectionDialog: false,
        person: [],
        allDates: [],
    };
  },
  methods: {
      loadAllDates() {

        this.$apiService.getCalendar().then((res) => {
            console.log('loadAllDates', res.data)
            if(res.data) {
                res.data.forEach((d) => {
                    let date = new Date(d.start);
                    let today = new Date();
                    let d1 = date.getFullYear()+''+ (date.getMonth() + 1) + '' +date.getDate();
                    let d2 = today.getFullYear()+''+ (today.getMonth() + 1) + '' +today.getDate();
                    if(d1>d2){
                        this.allDates.push(date.getDate()+'.'+(date.getMonth()+1) +'.'+date.getFullYear()+' ' + date.getHours()+':'+ date.getMinutes()+':'+ date.getSeconds());
                    }
                });
            }
            this.events = res.data;
        });

      },
      addPerson() {
          this.person.push({
            name: '',
            status: ''
          });
      },

      openDataProtectionDialog() {
          this.dataProtectionDialog = true;
          //window.open(link, '_blank');
          //this.$router.push(link); 
      },
      save() {
        if(this.$refs.registerForm.validate()) {
            let dateTime = this.register.date.split(' ');
            if(dateTime.length == 2) {
                this.register.date = dateTime[0];
                this.register.time = dateTime[1];
            }
            this.$apiService.saveRegistration(this.register).then((res) => {
              
              if(this.person.length>0) {
                  this.person.forEach((p, index) => {
                      this.register.name = p.name;
                      this.register.status = p.status;
                      this.$apiService.saveRegistration(this.register).then(() => {
                        if(index == this.person.length-1) {
                            this.resetData(res.data);
                        }
                      });
                      
                  });
              } else {
                  this.resetData(res.data);
              }
              
          });
        }
          
      },

      resetData(data) {
        this.dialogData = data;
        this.register = {
            date: '',
            time: ''
        };
        this.person = [];
        this.dialog = true;
        this.$refs.registerForm.reset()
      }
  },
  mounted() {
      this.loadAllDates();
      console.log('-',this.$route.params.datetime)
      if(typeof this.$route.params.datetime != 'undefined') {
        let datetime = this.$route.params.datetime.split(' ');
        this.register.date = datetime[0];
        this.register.time = datetime[1];
        var date = new Date(this.register.date);
        this.formatRegisterDate = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
      }
      
      
      if(typeof this.$route.params.type != 'undefined') {
        this.registername = this.$route.params.type;
      }
  }
};
</script>
