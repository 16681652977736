<template>
  <div v-if="news != ''">
    <v-row class="info" >

        <v-col v-html="news_"></v-col>
    </v-row>

    
  </div>
</template>

<script>

export default {
  
  data () {
    return {
        news_: ''
    };
  },
  props: {
      news: { default: '' },
  },
  methods: {
      getNews() {
          this.$apiService.getNews().then((res) => {
              if(res.data != '' && res.data != '-') {
                  this.news = res.data;
              }
              
          });
      }
  },
  mounted() {
      if(this.news == '') {
          this.getNews();
      } else {
          this.news_ = this.news;
      }
      
  }
};
</script>
