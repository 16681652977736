import Vue from 'vue';
/* import Vuetify from 'vuetify'; */
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/lib/locale/de'


Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#8bc34a', //23A89B
        secondary: '#787878',
        accent: '#087607',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#4caf50' //4CAF50 8bc34a
      },
      light: {
        primary: '#8bc34a',
        secondary: '#cccccc', //CFD8DC
        accent: '#0E980D',
        error: '#f44336',
        warning: '#ff9800',
        info: '#64B5F6',
        success: '#4caf50'
      }
    },
  },
};
export default new Vuetify(opts);
