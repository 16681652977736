<template>
  <div>
    <h2 v-if="header" style="text-align: center; margin: 60px;">
        Meine Zeit steht in deinen Händen ... (Ps 31,16a)
    </h2>
    <v-row class="fill-height">
    <v-col>
      <v-sheet height="70" v-if="header">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Heute
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            style="margin: 0px 8px"
            icon
            outlined
            small
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn
            icon
            outlined
            small
            style="margin: 0px 4px"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet :height="height" style="min-height: 720px">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :type="type"
          @click:event="showEvent"
          @click:more="resizeHeigh"
          @change="updateRange"
          :weekdays="weekdays"
          locale="de"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            max-width="640px"
            min-width="280px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title> {{selectedEvent.name}}  [{{selectedEvent.timeStartEnd}} Uhr]</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>mdi-close </v-icon>
              </v-btn>

            </v-toolbar>
            <v-card-text style="font-size: 16px">
              <div v-if="selectedEvent.details != ''" v-html="selectedEvent.details" style="background-color: #dfdfdf; padding: 10px 6px;margin:14px 0px;"></div>
              <div v-if="selectedEvent.transmission && selectedEvent.transmission.length > 0">
                <h4>Übertragung</h4>
                <span v-for="(tr, i) in selectedEvent.transmission" :key="i">
                    <v-btn  style="margin: 5px; color:#000; width: 260px" @click="goTo(tr.url)" >{{tr.name}}</v-btn>
                </span>
                
              </div>
              <div style="margin-top: 20px;">
                  <h4>Anmeldung</h4>
                  <v-btn :to="'/register/'+selectedEvent.name+'/'+selectedEvent.start">Zur Anmeldung</v-btn>
              </div>
              
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
  </div>
</template>

<script>

export default {
  props: {
      header: { default: true },
      height: { default: '63vH'}
  },
  data () {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      weekdays: [1,2,3,4,5,6,0],
      events: [],
      
    };
  },
  methods: {
    resizeHeigh() {
      let height = parseInt(this.height);
      if(height < 66) {
        this.height = '71vH';
      } else {
        this.height = parseInt(this.height) +8 + 'vH';
      }
    },
    goTo(url) {
        window.open(url, '_blank');
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      const events = []
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 25)
      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)
        const timeStartEnd = '';
        events.push({
          timeStartEnd: timeStartEnd,
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }
    },

    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    loadAllDates() {
      this.$apiService.getCalendar().then((res) => {
        this.events = res.data;
        this.events.forEach((event) => {
          event.timeStartEnd = this.$moment(event.start).format('HH:mm') + ' - ' + this.$moment(event.end).format('HH:mm');
        });
      });
    }

  },
  mounted() {
    this.loadAllDates();
  }
};
</script>

