<template>
  <div>
    <div class="bibelvers">
      Jesus spricht zu ihm: Ich bin der Weg und die Wahrheit und das Leben; 
      <br>
      niemand kommt zum Vater als nur durch mich!
      <br>
      Johannes 4,16
    </div>
    <h3>Liebe Geschwister und Freunde!</h3>
    <h3>Herzlich Willkommen!</h3>
    <br>
    <p>
        Wir freuen uns, dass unser Glaube nicht vergeblich ist und wir einen lebendigen Herrn haben! <br>
        Auf unterschiedlicher Weise dürfen wir wieder miteinander in Kontakt stehen, wofür wir Gott dankbar sind. 
        <br>
    </p>
    <p>
      Wir laden Sie <b>herzlich</b> zu den öffentlichen Gottesdiensten ein und freuen uns auf Ihren Besuch!
     <br>
     <br>
    </p>

    <p>Lasst uns weiter füreinander, für die Gottesdienste, für die Ukrainische Geschwister/ Bevölkerung und für die gesamte Situation beten!</p>
    <div style="margin: 40px 0px;">
      <transmission></transmission>
    </div>
    <h4>Öffentliche Gottesdienste / Termine</h4>
    <br>
    <calendar height="42vH" :header="false"></calendar>
    
  </div>
</template>

<script>
/* import Worship from '@/pages/worship'; */
import Transmission from '@/pages/transmission';
import Calendar from '@/pages/calendar';
export default {
  components: { Transmission, Calendar},
  data () {
    return {

    };
  },
  methods: {
   
  }
};
</script>
<style>

.bibelvers {
  text-align: center;;
  margin: 20px 0px;
  padding: 20px;
  background-color: #eee;
  font-size: 1.2em;
  font-weight: bold;
}
</style>