
import $http from '@/services/http';

let ApiService = {
  url: '',
  install (Vue, options) {
    this.url = options.url;
    Vue.prototype.$apiService = Vue.$apiService = this;
  },
  getWorship ( cancelToken) {
    return $http.get(`${this.url}/worship/`, {cancelToken: cancelToken});
  },
  getNews ( cancelToken) {
    return $http.get(`${this.url}/news/`, {cancelToken: cancelToken});
  },
  getTransmission ( cancelToken) {
    return $http.get(`${this.url}/transmission/`, {cancelToken: cancelToken});
  },
  getVirtualChoir ( cancelToken) {
    return $http.get(`${this.url}/virtual-choir/`, {cancelToken: cancelToken});
  },
  getCalendar ( cancelToken) {
    return $http.get(`${this.url}/calendar/`, {cancelToken: cancelToken});
  },
  saveRegistration  (register, cancelToken) {
    return $http.post(`${this.url}/register/add`, register, {cancelToken: cancelToken});
  },
  saveYoutubeUrl (url, cancelToken) {
    return $http.post(`${this.url}/set-youtube/add`, url, {cancelToken: cancelToken});
  },
  getVersEdification ( cancelToken) {
    return $http.get(`${this.url}/edification/vers`, {cancelToken: cancelToken});
  },
};

export default ApiService;
