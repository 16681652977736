
let config = {};

if (process.env.NODE_ENV == 'development') {
  config.apiUrl = 'http://website-api.vm/api';
  config.chorUrl = 'http://website.vm/chor';
} else {
  config.apiUrl = 'https://ecbg-rehe.de/api';
  config.chorUrl = 'https://ecbg-rehe.de/chor';
}

export default config;
