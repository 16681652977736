<template>
  <v-container>
      <v-card v-for="(source, i) in sources" :key="i" style="margin-top:30px">
          <v-card-title>
              <h3>{{source.name}}</h3>
          </v-card-title>
          <v-card-text>
              <video :poster="$chorUrl+'/'+source.poster" :src="$chorUrl+'/'+source.src" type='video/mp4' controls controlsList='nodownload' width="100%"></video>
          </v-card-text>
          
      </v-card>
      
  </v-container>
</template>

<script>

export default {
  
  data () {
    return {
        sources: []
    };
  },
  methods: {
      getVirtualChoir() {
          this.$apiService.getVirtualChoir().then((res) => {
              this.sources = res.data;
          });
      }
  },
  mounted() {
      this.getVirtualChoir();
  }
};
</script>

<style>
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
</style>
