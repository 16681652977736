<template>
  <div>
      
    <h4>Gottesdienst-Übertragungen</h4>
    <v-btn style="margin: 5px; color:#fff; width: 260px" class="primary"
        v-for="(trans, index) in transmission" :key="index"
        @click="goTo(trans.url)"
    >
        {{ trans.name }}
        
    </v-btn>
    <div style="margin:20px 0px 60px 0px">
        <p>Falls es bei der Übertragung zu Problemen kommt, dann helfen wir gerne.</p>
        <ul>
            <li><a href="tel:+49 151 64 03 70 07">+49 151 64 03 70 07</a></li>
            <li><a href="mailto:tontechnik@ecbg-rehe.de">tontechnik@ecbg-rehe.de</a></li>
        </ul>
    </div>
  </div>
</template>

<script>

export default {
  
  data () {
    return {
        transmission: []
    };
  },
  methods: {
      getTransmission() {
          this.$apiService.getTransmission().then((res) => {
              this.transmission = res.data;
          });
      },
      goTo(url) {
        window.open(url, '_blank');
        //this.$router.push(url);
      }
  },
  mounted() {
      this.getTransmission();
  }
};
</script>

<style>
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
</style>
