<template>
  <div>
    <div style="margin: 10px auto; text-align: center;padding: 10px; ">
      <div  style="margin: 10px auto; max-width: 640px; width: auto; min-width:240px">
        <img src="https://cloud.ecbg-rehe.de/s/X2W7yfYKg87jskt/download/spezial.jpg" width="100%" alt="Einladungskarte" >
      </div>
      
    </div>
    <div>
      <v-row>
        <v-col>
          <h3></h3>

        </v-col>
      </v-row>
    </div>
    <v-row style="text-align: center; margin-top: 20px; font-size: larger;">
      <v-col>
        Auch diese Gottesdienste werden online übertragen.<br>
        Falls Sie Fragen haben, oder gerne eine Tonaufnahme erhalten möchten,<br> schreiben Sie uns an: <b>info@ecbg-rehe.de</b>
      </v-col>
      

    </v-row>
    <div style="margin-top: 50px"></div>
    <v-row style=" text-align: center; margin: 5px 0px;" v-for="(trans, index) in transmission" :key="index"
        @click="goTo(trans.url)">

      <v-btn style="margin: 5px auto; color:#fff; padding: 20px; max-width:60%; min-width:55%"  class="primary"
        
    >
        {{ trans.name }}
        
    </v-btn>

    </v-row>
    
  </div>
</template>

<script>
/* import Worship from '@/pages/worship'; */

export default {
  data () {
    return {
        transmission: []
    };
  },
  methods: {
      getTransmission() {
          this.$apiService.getTransmission().then((res) => {
              this.transmission = res.data;
          });
      },
      goTo(url) {
        window.open(url, '_blank');
        //this.$router.push(url);
      }
  },
  mounted() {
      this.getTransmission();
  }
};
</script>
<style>

.bibelvers {
  text-align: center;;
  margin: 20px 0px;
  padding: 20px;
  background-color: #eee;
  font-size: 1.2em;
  font-weight: bold;
}
</style>