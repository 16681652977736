<template>
  <div>
      <div>
          <p>Evangeliums-Christen-Baptistengemeinde Rehe</p>
          <p>Buchenweg 2</p>
          <p>56479 Rehe</p>
          <p>E-Mail: <a href="mailto:info@ecbg-rehe.de">info@ecbg-rehe.de</a></p>
      </div>
      <div style="margin: 40px 0px">
        <v-btn to="/data-protection" class="primary">Zur Datenschutzerklärung</v-btn>
      </div>
      <div>
          <div class="mapouter" style="float: left;">
              <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Buchenweg%202%20Rehe&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    <br>
                    <a href="https://www.embedgooglemap.net">google embed map</a>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  
  data () {
    return {
        news: ''
    };
  },
  methods: {

  },
  mounted() {
   
  }
};
</script>
<style>
.mapouter{position:relative;text-align:right;height:500px;width:100%;}

.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}
</style>
