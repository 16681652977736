import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/pages/home.vue';
import Special from '@/pages/special.vue';
import Worship from '@/pages/worship.vue';
import PublicHoliday from '@/pages/public-holiday.vue';
import Register from '@/pages/register.vue';
import Contact from '@/pages/contact.vue';
import DataProtection from '@/pages/data-protection.vue';
import VirtualChoir from '@/pages/virtual-choir.vue';
import Youtube from '@/pages/youtube.vue'
import Calendar from '@/pages/calendar.vue'
import Edification from '@/pages/edification.vue'
Vue.use(Router);

const router = new Router({
  //base: '/app/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/special',
      name: 'special',
      component: Special
    },
    {
      path: '/edification',
      name: 'Erbauliches',
      component: Edification
    },
    {
      path: '/worship',
      name: 'Gottesdienste',
      component: Worship
    },
    {
      path: '/public-holidays',
      name: 'Feiertage',
      component: PublicHoliday
    },
    {
      path: '/register',
      name: 'Anmeldung',
      component: Register
    },
    {
      path: '/register/:type/:datetime',
      name: 'Anmeldung mit DateTime',
      component: Register
    },
    {
      path: '/virtual-choir',
      name: 'VirtuellerChor',
      component: VirtualChoir
    },
    {
      path: '/contact',
      name: 'Kontakt',
      component: Contact
    },
    {
      path: '/calendar',
      name: 'Kalender',
      component: Calendar
    },
    {
      path: '/data-protection',
      name: 'Datenschut',
      component: DataProtection
    },
    {
      path: '/set-youtube-url',
      name: 'Youtube',
      component: Youtube
    },
  
    
  ]
});

//router.beforeEach(loginGuard(router));

export default router;
