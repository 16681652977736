<template>
  <v-card>
    <v-card-title>
      <h4>
      Zu den Feiertagen laden wir Sie ganz herzlich ein. 
    </h4>
    </v-card-title>
    <v-card-text>
      <br>
      <p>Die Termine entnehmen Sie dem <router-link to="calendar">Kalender</router-link></p>
      <p>Lasst uns gemeinsam in den bevorstehenden Feiertagen Gott loben und an seinen Sohn Jesus Christus gedenken!</p>
      <p>Wir würden uns über eine <router-link to="/register">Anmeldung</router-link> vorab freuen, da es den Organisationsaufwand erleichtert.</p>
    </v-card-text>
    
  </v-card>
  
</template>

<script>

export default {
  
  data () {
    return {
        news: []
    };
  },
  methods: {

  },
  mounted() {
      
  }
};
</script>
