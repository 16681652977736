<template>
  <v-app dark>
    <v-card id="app" style="border-radius:0px;">
      <v-card-title dark style="background-color: rgb(30,30,30); border:0px;" >
        <v-row style="min-height: 90px">
          <span style="color: white; padding: 20px">Ev.-Ch.-Baptistengemeinde Rehe</span>
          
          <v-spacer></v-spacer>
          <img src="img/logo_Weiss_icon.png" style="margin: 10px" height="70px" alt="Logo" />
        </v-row>
       <v-tabs
          dark
          show-arrows
          v-model="tab"
          centered
        >
          <v-tab v-for="menue in menues" :key="menue.name" :to="menue.link">
            {{menue.name}}
          </v-tab>

        </v-tabs>

      </v-card-title>
      <v-banner v-if="news.text != '' && news.expiry_date.getTime() > today.getTime() && $route.path != '/register'" class="info" >
          <news :news="news.text"></news>
          <v-btn style="margin-top: 15px; float: right" to="/register" v-if="news.with_register">Zur Anmeldung</v-btn>
        </v-banner>
      <v-card-text >
        
        <v-container class="content">
          <router-view></router-view>
        </v-container>
      

      </v-card-text>
    </v-card>
  </v-app>
  
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import News from '@/pages/news.vue';
export default {
  name: 'App',
  components: {
    News
  },
  data: () => ({
    tab: '',
    today: '',
    news: {
      text: '',
      expiry_date: ''
    },
    menues: [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'Termine',
        link: '/calendar'
      },
      {
        name: 'Erbauliches',
        link: '/edification'
      },
      {
        name: 'Impressum',
        link: '/contact'
      }
    ]
  }),
  methods: {
    goTo(url) {
      window.open(url, '_blank');
    },
    getNews() {
      this.$apiService.getNews().then((res) => {
        this.today = new Date();
          if(res.data.text != '' && res.data.text != '-') {
              this.news = res.data;
              this.news.expiry_date = new Date(res.data.expiry_date);
          }
      });
    }
  },
  mounted() {
    this.getNews();
  },
}
</script>

<style>
body{
  border:0px;
}
#app {
  height: 100%;
  border: 0px !important;
}

.content {
  font-size: 1.0em;
}
.v-card__title{
  font-size: 1.2em !important;
}
.v-card__text{
  font-size: 1.0em !important;
}
</style>
