import axios from 'axios';
import router from '@/router';

let options = {

};

let $http = axios.create(options);

$http.interceptors.request.use((config) => {
  
  let token = localStorage.getItem('jwt-token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    //router.app.$session.logout();
  }
  return config;
}, (err) => {
  return Promise.reject(err);
});

$http.interceptors.response.use(function (response) {
  if (response.data.token) {
    router.app.$session.renew(response.data.token);
  }
  if(response.request.response.indexOf('<b>Fatal error</b>:') == -1 ) {
    response.data = response.data.data || {};
    return response;
  } else {
    console.log(response, '!!!!!!!!error!!!!!!!!!!!!!');
  }
  
}, function (err) {
  if (axios.isCancel(err)) {
    return Promise.reject(err);
  } else {
    if (err.response.status == 401) {
      //router.app.$session.logout();
      return Promise.resolve();
    } else {
      return Promise.reject(err);
    }
  }
});

export default $http;
