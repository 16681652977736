<template>
  <div class="container">
    <v-card class="distance">
      <v-card-title><b>In Christus</b> </v-card-title>
      <v-card-text>
        <v-img
          src="img/in_christus.png"
          max-width="70%"
          min-width="300px"
          style="text-align: center; margin: 0px auto"
          alt="In Christus Aussagen mit Bibelstelle"
        ></v-img>
        <div class="distance">
          <p><b>„In Christus“ zu sein ist der Schlüssel zum Leben.</b></p>
          <p>
            Immer und immer wieder hält uns Apostel Paulus dazu an, in Christus
            zu leben. Wahrscheinlich wissen die meisten, dass wir von Gott
            geliebt und errettet sind. Manche kennen sogar einiger dieser „in
            Christus“ Verse auswendig oder haben sie als Bild an der Wand hängen
          </p>
          <p>
            Und trotzdem haben viele Schwierigkeiten zu glauben und anzunehmen,
            was Gott über uns sagt. Wir tragen Schuldgefühle, Unsicherheiten und
            ein niedriges Selbstwertgefühl mit uns herum und hindern uns selbst
            Gottes Liebe in Anspruch zu nehmen
          </p>
          <p>
            Vielleicht denken wir zu viel an unsere Unzulänglichkeiten und
            Misserfolge, dass wir ganz vergessen daran zu denken, wie viel
            Schönes Gott in unserem Leben schon bewirkt hat. Aber anstelle ein
            Selbsthilfe-Projekt zu starten, um unser Selbstbewusstsein zu
            puschen, leitet die Bibel uns dazu an, die Verherrlichung Gottes in
            den Fokus zu rücken
          </p>

          <p>
            Richtige Selbstliebe ist ein natürliches Nebenprodukt der
            Gottesliebe.
          </p>

          <p>
            Die wahre Quelle des Selbstbewusstseins und der Identität ist den zu
            lieben, der die Liebe ist und zu erkennen, dass wir auch von ihm
            geliebt und angenommen sind.
          </p>

          <p>
            Wenn du also Schwierigkeiten hast, diesen „in Christus“ Wahrheiten
            oder andern Aspekten deiner Identität in Christus zu glauben und
            anzunehmen, kannst du damit anfangen Gott zu lieben und zu
            verherrlichen.<br />
            Denn er ist es, der dich zuerst geliebt hat.<br />
            Im Gespräch mit Gott und Freunden, geistliche Leiter und Seelsorger
            wirst du lernen, Gottes Liebe wirklich anzunehmen und deine
            Identität in Christus finden und stärken.
          </p>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="distance">
      <v-card-title> Tagesvers </v-card-title>
      <v-card-text>
        <h4>{{ vers }}</h4>
      </v-card-text>
    </v-card>

    <v-card class="distance">
      <v-card-title> Referenz </v-card-title>
      <v-card-text>
        <ul>
          <li>
            <a href="https://www.lebenistmehr.de/leben-ist-mehr.html"
              >Tageskalender (Leben ist mehr / CLV)</a
            >
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <!-- <iframe src="https://www.lebenistmehr.de/leben-ist-mehr.html" frameborder="0" width="100%" height="100%" style="height: 100%; width=100%"></iframe> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      vers: "",
    };
  },
  methods: {},
  mounted() {
    this.$apiService.getVersEdification().then((res) => {
      console.log(res);
      this.vers = res.data.bible_vers;
    });
  },
};
</script>

<style>
.distance {
  margin-top: 40px;
}
</style>
